
.form-control-chips {
	margin: 40px 0px;
	margin-left: 0;
	display: block;
	display: grid;
	grid-row-gap: 20px;
}

.form-control-cat {
	margin: 40px 0px;
	margin-left: 0;
	display: block;
}

.form-control-chip {
	display: inline-block;
	text-align: center;
	padding: 5px 8px;
	margin: 0px 3px 5px 3px;
	background-color: #6096ba;
	color: #3c4043;
	border-radius: 56px;
	font-weight: 400;
	font-size: 11pt;
	border: 1px solid #dfe1e5;
	min-width: 100px;
}

.form-control-chip:hover {
	cursor: pointer;
	-webkit-transform: scale(1.05);
	        transform: scale(1.05);
}

.custom-control-input {
	z-index: 2 !important;
}

.custom-control-label {
	font-size: 12pt;
}

.custom-control-label::before,
.custom-control-label::after {
	top: 0.1rem;
	width: 1.25rem;
	height: 1.25rem;
}

.form-control.is-invalid {
	border-color: #dc3545;
	background-image: none !important;
	border-width: 1.6px;
	padding-right: 0.75rem;
}

.form-control.is-invalid-format {
	border-color: #dc3545;
	background-image: none !important;
	border-width: 1.6px;
	padding-right: 0.75rem;
}

.bold-form::-webkit-input-placeholder {
	font-weight: normal;
}

.bold-form::placeholder {
	font-weight: normal;
}

.bold-form {
	font-weight: bold;
}

#overlay-background {
	height: 100vh;
	width: 100vw;
	position: absolute;
	top: 0;
	left: 0;
	background-color: white;
	text-align: center;
	z-index: 1050;
	padding-top: 10em;
}

#overlay-background .spinner {
	width: 50px;
	height: 50px;
}

#overlay-background h1 {
	font-size: 48pt;
	font-weight: 400 !important;
}

#overlay-background p {
	font-size: 28pt;
}

#overlay-background img {
	max-width: 500px;
	margin-bottom: 30px;
	/*animation: blinker 2s linear infinite;*/
}

@-webkit-keyframes blinker {
	50% {
		opacity: 0;
	}
}

@keyframes blinker {
	50% {
		opacity: 0;
	}
}

#alerts-wrapper .close{
    /* padding-top: .35em;
    padding-right: 0.4em; */
}
#order-filters {
	margin-bottom: 10px;
}

#order-filters .row div {
	/* margin-right: 2px; */
	padding-right: 2px;
}
#order-filters .react-datepicker-wrapper {
	display: block;
}

.hidden {
	display: none;
}

#order-filters{
    margin-bottom: 10px;
}

#order-filters .row div{
    /* margin-right: 2px; */
    padding-right: 2px;
}
#order-filters .react-datepicker-wrapper{
    display: block;
}
div.order-details{
    margin-bottom: 0;
}
div.order-details ul{
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
}


#main-container {
	width: 100%;
}

.search-form {
	display: flex;
	justify-content: center;
	width: 35%;
}

.search-title {
	color: #fff;
	margin: auto 0px;
	font-size: 1.2rem;
	margin-right: 30px;
}

.search-input {
	background-color: white;
}

.search-button {
	background: transparent;
	border: none;
	outline: none;
	margin-left: -50px;
}

.search-button img {
	width: 20px;
	height: 20px;
	object-fit: cover;
}

.checkboxes {
	display: flex;
	justify-content: end;
}

.custom-checkbox-hidden {
	display: none;
}

#addClientForm .row > div{
    margin-bottom: 10px;
}
#addClientForm .row > div{
    margin-bottom: 10px;
}
.modal-content {
	width: 90%;
	margin-left: 10%;
	margin-right: 10%;
	padding: 0 3rem;
}

#order-modal .params-row {
	margin-bottom: 10px;
}

#order-modal .row > div:not(:first-child) {
	/* border:1px solid black; */
	padding-left: 2px;
}

#order-add-table ul {
	margin-bottom: 0;
}

.form-control-cat {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}
.form-control-chip {
	flex: 0 0 auto;
	margin: 10px;
}
.form-control-chips {
	display: grid;
	flex-wrap: wrap;
	justify-content: center;
}
#order-modal .form-control-chips .form-control-chip.active {
	box-shadow: 0px 0px 0px 5px #7dce13;
}

#order-modal .form-control-chips .cat-chip.active {
	box-shadow: 0px 0px 0px 5px #11316f;
}

#order-modal .form-control-chips .form-control-chip {
	color: black;
	font-weight: bold;
	margin: auto 10px;
}

#order-modal .form-control-cat .form-control-cat {
	color: black;
	font-weight: bold;
	margin: 5px 10px;
	width: 120px;
}

#order-modal .form-control-cat .form-control-chip.active {
	box-shadow: 0px 0px 0px 5px #7dce13;
}

#order-modal .form-control-cat .cat-chip.active {
	box-shadow: 0px 0px 0px 5px #11316f;
}

#order-modal .form-control-cat .form-control-chip {
	color: black;
	font-weight: bold;
	margin: 5px 10px;
}

#order-modal .form-control-cat .form-control-cat {
	color: black;
	font-weight: bold;
	margin: 5px 10px;
	width: 120px;
}

.form-control-chip.axelle38 {
	background: #0652dd;
}

.form-control-chip.axelle38prs {
	background: #0652dd;
}

.form-control-chip.axelle70prs {
	background: #ffb142;
}

.form-control-chip.axelle70 {
	background: #f6e58d;
}

.form-control-chip.axelletorique38 {
	background: #ff3838;
}

.form-control-chip.axelletorique70 {
	background: #ced6e0;
}

.form-control-chip.axelletorique38prs {
	background: #be2828;
}

.form-control-chip.axelletorique70prs {
	background: #acb6c3;
}

.form-control-chip.verde {
	background: #7bed9f;
}

.form-control-chip.giallo {
	background: #ffd32a;
}

.form-control-chip.perla {
	background: #d2dae2;
}

.form-control-chip.spice {
	background: #8d6e63;
}

.form-control-chip.dkkera {
	background: #64b5f6;
}

#order-modal .form-control-chips .form-control-chip.chip-blue {
	background: #3498db;
	color: white;
}

#order-modal .form-control-chips .form-control-chip.chip-blue.active {
	border: 1px solid #2980b9;
}

/* DONE BY YACINE */
.cat-chip {
	display: inline-block;
	color: #111;
	border: 0px solid #ccc;
	background: #007bff;
	box-shadow: 0 0 5px -1px rgb(0 0 0 / 20%);
	cursor: pointer;
	vertical-align: middle;
	padding: 10px;
	text-align: center;
	border-radius: 5px;
	width: 120px;
}

.form-control-chip {
	display: inline-block;
	color: #111;
	border: 0px solid #ccc;
	background: #6096ba;
	box-shadow: 0 0 5px -1px rgb(0 0 0 / 20%);
	cursor: pointer;
	vertical-align: middle;
	padding: 10px;
	text-align: center;
	border-radius: 5px;
	width: 120px;
}

.large-cat {
	width: 165px !important;
}

.sclerale-torique {
	max-width: 130px;
}

.large-label {
	max-width: 140px;
}

.hidden {
	display: none;
}

.disabled {
	box-shadow: none !important;
}

.disabled:hover {
	cursor: not-allowed;
}

.disabled:hover::before {
	content: "Produit non disponible!"; /* Set the message content */
	position: absolute; /* Position the element absolutely */
	top: -20px; /* Move the element up */
	left: 0; /* Align the element with the div */
	padding: 5px; /* Add some padding to the element */
	background-color: #333; /* Set the background color */
	color: #fff; /* Set the text color */
	font-size: 12px; /* Set the font size */
	border-radius: 5px; /* Add some rounded corners */
}

.sign-missing-input::after {
	content: "Un signe doit se placer en premi\E8re position !"; /* Set the message content */
	position: absolute; /* Position the element absolutely */
	top: -20px; /* Move the element up */
	left: 0; /* Align the element with the div */
	padding: 5px; /* Add some padding to the element */
	background-color: #333; /* Set the background color */
	color: #fff; /* Set the text color */
	font-size: 12px; /* Set the font size */
	border-radius: 5px; /* Add some rounded corners */
}

.parse-error-input::before {
	content: "Erreur le champ doit contenir un nombre !"; /* Set the message content */
	position: absolute; /* Position the element absolutely */
	top: -20px; /* Move the element up */
	left: 0; /* Align the element with the div */
	padding: 5px; /* Add some padding to the element */
	background-color: #333; /* Set the background color */
	color: #fff; /* Set the text color */
	font-size: 12px; /* Set the font size */
	border-radius: 5px; /* Add some rounded corners */
}

.top {
	grid-area: 1;
}

.secondrow {
	grid-area: 2;
}

.bottom {
	grid-area: 3;
}

#order-modal-stock{
    min-width: 700px;
}
#order-modal-stock .modal-body{
    height: 60vh;
    overflow-y: scroll;
    overflow-x: hidden;
}
#order-modal-stock .modal-body #params-row{
    padding-right: 0!important;
    padding-left: 0!important;
}
#order-modal-stock #params-row .col-sm-6{
    padding-right: 0!important;
}
#order-modal-stock .powers-row{
    padding-right: 0!important;
    margin-bottom: 10px;
    margin-left:20px;
    margin-top:25px;
}
#order-modal-stock #powers-choose-group{
    margin-top:20px;
    margin-bottom:20px;
}
#order-modal-stock .row.powers-row > div{
    padding-right: 0;
    padding-left:0;
}
/* #order-modal-stock .row > div:nth-child(4n + 1){
    padding-right: 2px;
    padding-left:10px;
}
#order-modal-stock .row > div:nth-child(4n + 2){
    padding-right:3px;
}
#order-modal-stock .row > div:nth-child(4n){
    padding-left: 2px;
    padding-right: 10px;;
} */
#order-add-table ul{
    margin-bottom: 0;
}
#order-modal-stock .powers-row label{
    font-weight: bold;
}
#order-modal-stock .powers-row .col-3:first-child label{
    /* margin-left:3px;
    margin-right:3px; */
}
/* #order-modal-stock .powers-row .col-3.positive-powers:not(:first-child) label{
    margin-left:3px;
    margin-right:3px;
} */
#order-modal-stock .powers-row .form-control{
    display: inline;
    width: 50%;
    margin-right:30px;
    margin-top:-10px;
    margin-bottom: 15px;
    float: right;
}
#order-modal-stock .form-control-chips{
}
#order-modal-stock .form-control-chips .form-control-chip{
    width: 48%;
    margin: 0;
    color: white;
    text-transform: capitalize;
}
#order-modal-stock .form-control-chips .form-control-chip.active{
    box-shadow: 0px 0px 5px 5px #353b48;
}
#order-modal-stock .form-control-chips .form-control-chip.negative-chip{
    background: #007bff;
}
#order-modal-stock .form-control-chips .form-control-chip.positive-chip{
    background: #dc3545;
}
#order-modal-stock .form-control-chips .form-control-chip:first-child{
    margin-right:10px;
}
#orders-table, #orders-table thead, #orders-table tbody{
    width: 100px;
}
#orders-table td{

}
#group-actions .row{

}

#group-actions .row .col{
    margin-right: 0;
    padding-right: 0;
}
#group-actions .row .col:not(:first-child){
    padding-left: 1px;
}
#orders-table, #orders-table thead, #orders-table tbody{
    width: 100px;
}
#orders-table td{

}
#group-actions .row{

}

#group-actions .row .col{
    margin-right: 0;
    padding-right: 0;
}
#group-actions .row .col:not(:first-child){
    padding-left: 1px;
}

.form-signin h1{
    text-align: center;
  }
  .form-signin {
    width: 100%;
    max-width: 600px;
    padding: 15px;
    margin: auto;
    margin-top:10em;
  }
  .form-signin .checkbox {
    font-weight: 400;
  }
  .form-signin .form-control {
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 10px;
    font-size: 16px;
  }
  .form-signin .form-control:focus {
    z-index: 2;
  }
  .form-signin input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  
#orders-table, #orders-table thead, #orders-table tbody{
    width: 100px;
}
#orders-table td{

}
#group-actions .row{

}

#group-actions .row .col{
    margin-right: 0;
    padding-right: 0;
}
#group-actions .row .col:not(:first-child){
    padding-left: 1px;
}
.form-signin h1 {
	text-align: center;
}
.form-signin {
	width: 100%;
	max-width: 600px;
	padding: 15px;
	margin: auto;
	margin-top: 10em;
}
.form-signin .checkbox {
	font-weight: 400;
}
.form-signin .form-control {
	position: relative;
	box-sizing: border-box;
	height: auto;
	padding: 10px;
	font-size: 16px;
}
.form-signin .form-control:focus {
	z-index: 2;
}
.form-signin input[type="email"] {
	margin-bottom: -1px;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
	margin-bottom: 10px;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.form-signin h1 {
	text-align: center;
}
.form-signin {
	width: 100%;
	max-width: 600px;
	padding: 15px;
	margin: auto;
	margin-top: 10em;
}
.form-signin .checkbox {
	font-weight: 400;
}
.form-signin .form-control {
	position: relative;
	box-sizing: border-box;
	height: auto;
	padding: 10px;
	font-size: 16px;
}
.form-signin .form-control:focus {
	z-index: 2;
}
.form-signin input[type="email"] {
	margin-bottom: -1px;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
	margin-bottom: 10px;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

body {
	font-size: 0.875rem;
}

.navbar-brand {
	padding-top: 0px !important;
	padding-bottom: 0px !important;
	text-align: center;
	background-color: white !important;
}

.navbar-brand img {
	width: 150px;
}

.feather {
	width: 16px;
	height: 16px;
	vertical-align: text-bottom;
}

/*
   * Sidebar
   */

.sidebar {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	z-index: 100;
	/* Behind the navbar */
	padding: 48px 0 0;
	/* Height of navbar */

	box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

.navbar-dark .navbar-nav .nav-link {
	color: white;
}

.table-striped tbody tr:nth-of-type(odd) {
	background-color: #e1f5fe;
}

.bg-light-blue {
	padding-top: 65px;
	background-color: #e1f5fe;
}

.bg-dark-blue {
	background-color: #2196f3;
}

.sidebar-sticky {
	position: relative;
	top: 0;
	height: calc(100vh - 48px);
	padding-top: 0.5rem;
	overflow-x: hidden;
	overflow-y: auto;
	/* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
	.sidebar-sticky {
		position: -webkit-sticky;
		position: sticky;
	}
}
.modal-dialog {
	min-width: 80% !important;
	padding: 2rem;
}
.sidebar .nav-link {
	font-weight: 500;
	color: #333;
	/* color: #333; */
}

.sidebar .nav-link .feather {
	margin-right: 4px;
	color: #999;
}

.sidebar .nav-link.active {
	color: #007bff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
	color: inherit;
}

.sidebar-heading {
	font-size: 0.75rem;
	text-transform: uppercase;
}

/*
   * Content
   */

[role="main"] {
	padding-top: 133px;
	/* Space for fixed navbar */
}

@media (min-width: 768px) {
	[role="main"] {
		padding-top: 48px;
		/* Space for fixed navbar */
	}
}

/*
   * Navbar
   */

.navbar-brand {
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
	font-size: 1rem;
	background-color: rgba(0, 0, 0, 0.25);
	box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.25);
}

.navbar .form-control {
	padding: 0.75rem 1rem;
	border-width: 0;
	border-radius: 7px;
}

.form-control-dark {
	color: #fff;
	background-color: rgba(255, 255, 255, 0.1);
	border-color: rgba(255, 255, 255, 0.1);
}

.form-control-dark:focus {
	border-color: transparent;
	box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.25);
}

.sidebar-sticky::-webkit-scrollbar {
	width: 10px;
}

.sidebar-sticky::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
	/* border-radius: 10px; */
}

.sidebar-sticky::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
}
@media (max-width: 575px) {
	.bg-light-blue {
		padding-top: 100px;
	}
}
@media (max-width: 340px) {
	.bg-light-blue {
		padding-top: 140px;
	}
}
/* Printing */
@media print {
	.table,
	.table tr,
	.table th,
	.table td {
		border: 1px solid black;
		padding: 5px;
	}

	.sidebar {
		display: none;
		width: 0 !important;
	}

	.btn {
		display: none;
	}

	main {
		width: 100% !important;
		max-width: 100% !important;
	}
}

